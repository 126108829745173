import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Icon } from '@/core/components/component-library/Icon/Icon';
import { Button } from '@/core/components/ui/button';
import { useAuthenticationContext } from '@/core/context/AuthenticationContext';

export function FreeplanReachedBanner(): JSX.Element {
  const { isLoggedIn, openLoginModal } = useAuthenticationContext();
  const { $t } = useIntl();
  const navigate = useNavigate();

  return (
    <div className="bg-warning-main flex flex-col md:flex-row justify-center items-center p-4 gap-2 pt-28 lg:pt-4">
      <div className="flex justify-center items-center gap-2">
        <Icon type={Icon.type.ALERT_FILLED} />
        <span>{$t({ id: 'General.freePlanLimitReached' })}</span>
      </div>
      <div className="flex justify-center items-center gap-2">
        <Button
          size="sm"
          color="neutral"
          onClick={() => {
            isLoggedIn ? navigate('/settings/subscription') : openLoginModal();
          }}
        >
          {$t({ id: 'General.checkMyUsage' })}
        </Button>
        <Button
          size="sm"
          onClick={() => {
            navigate('/pricing');
          }}
        >
          {$t({ id: 'General.comparePlans' })}
        </Button>
      </div>
    </div>
  );
}
