import { createContext, useContext, useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { PageLoader } from '@/core/components/layout/page-loader';
import { useAuthenticationContext } from '@/core/context/AuthenticationContext';
import {
  Company,
  Languages,
  Project,
  ProjectInvitation,
} from '@/generated/api';

import { RFQNavitationType, useRFQSummary } from '../hooks/useRFQSummary';

export interface RFQContext {
  rfq: Project;
  isOwner: boolean;
  isUserInTenderArea: boolean;
  isLocked: boolean;
  invitation?: ProjectInvitation;
  isLoading?: boolean;
  invitationToken?: string;
  refetch?: () => void;
}

const RFQContext = createContext<RFQContext>({
  rfq: {
    id: 0,
    title: 'CONTEXT NOT LOADED',
    incoterms: null,
    deadline: '2099-01-01',
    created_at: '2000-01-01',
    updated_at: '2000-01-01',
    currency: null,
    projectId: null,
    deliveryDate: null,
    isNdaProtected: false,
    isPrivate: false,
    isOnBehalf: false,
    note: null,
    reasonForRequest: Project.reasonForRequest.BENCHMARKING,
    languages: [Languages.EN],
    status: Project.status.CLOSED,
    userId: 0,
    companyId: 0,
    additionalFiles: [],
    ndaFile: null,
    parts: [],
    zipFile: null,
    certifications: [],
    tenderArea: [],
    invitations: [],
  },
  isOwner: false,
  isUserInTenderArea: false,
  isLocked: false,
  isLoading: false,
  refetch: () => {},
});

export function RFQContextProvider(): JSX.Element {
  const { pathname } = useLocation();
  const { invitationToken, projectId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuthenticationContext();
  const {
    loading: isLoading,
    rfq,
    invitation,
    refetch,
  } = useRFQSummary({
    idOrToken: invitationToken || projectId,
    type: invitationToken ? RFQNavitationType.TOKEN : RFQNavitationType.LINK,
    isEdit: pathname.includes('edit'),
  });
  const isOwner = useMemo<boolean>(
    () => !!rfq && currentUser?.company.id === rfq.companyId,
    [rfq, currentUser],
  );
  const isUserInTenderArea = useMemo<boolean>(() => {
    if (!!rfq && (rfq.tenderArea === null || rfq.tenderArea.length === 0))
      return true;
    if (currentUser === null) return true; // access via token
    if (!!rfq && rfq.companyId === currentUser.company.id) return true;
    if (
      !!rfq &&
      rfq.tenderArea?.includes(currentUser?.company?.address?.country || '')
    )
      return true;

    return false;
  }, [rfq, currentUser]);

  const isLocked = useMemo<boolean>(() => {
    if (
      currentUser &&
      currentUser.company.membershipPlan &&
      [
        Company.membershipPlan.TIER1,
        Company.membershipPlan.TIER2,
        Company.membershipPlan.TIER3,
      ].includes(currentUser.company.membershipPlan)
    )
      return false;

    if (invitation?.isLocked && !currentUser) return true;
    if (invitation?.isLocked && currentUser?.company.membershipPlan === null)
      return true;

    return false;
  }, [currentUser, invitation?.isLocked]);

  useEffect(() => {
    if (!isLoading && !rfq) {
      invitationToken
        ? navigate(`/project-not-found?token=${invitationToken}`)
        : navigate(`/project-not-found?id=${projectId}`);
    }
  }, [isLoading, rfq, navigate, projectId, invitationToken]);

  useEffect(() => {
    if (invitationToken && currentUser && rfq)
      navigate(`/project/${rfq.id}/view`);
  }, [currentUser, invitationToken, navigate, rfq]);

  return rfq && !isLoading ? (
    <RFQContext.Provider
      value={{
        rfq,
        isOwner,
        isUserInTenderArea,
        isLocked,
        invitationToken,
        invitation,
        isLoading,
        refetch,
      }}
    >
      <Outlet />
    </RFQContext.Provider>
  ) : (
    <PageLoader />
  );
}

export function useRFQContext(): RFQContext {
  return useContext(RFQContext);
}
