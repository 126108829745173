/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { Company } from '../models/Company';
import type { ProjectInvitation } from '../models/ProjectInvitation';
import type { RequestNdaDto } from '../models/RequestNdaDto';

export class InvitationsService {
  /**
   * @param token
   * @returns ProjectInvitation
   * @throws ApiError
   */
  public static invitationsControllerFindFromToken(
    token: string,
  ): CancelablePromise<ProjectInvitation> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/invitations/tokens/{token}',
      path: {
        token: token,
      },
    });
  }

  /**
   * @param token
   * @returns any Endpoint to mark invitation as viewed as a non-authenticated user
   * @throws ApiError
   */
  public static invitationsControllerMarkInvitationViewedByToken(
    token: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/invitations/tokens/{token}/mark-viewed',
      path: {
        token: token,
      },
    });
  }

  /**
   * @param rfqId
   * @returns ProjectInvitation
   * @throws ApiError
   */
  public static invitationsControllerFind(
    rfqId: number,
  ): CancelablePromise<ProjectInvitation> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/invitations/projects/{rfqId}',
      path: {
        rfqId: rfqId,
      },
    });
  }

  /**
   * @returns string
   * @throws ApiError
   */
  public static invitationsControllerCountFreeInvitationsThisMonth(): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/invitations/free-monthly-count',
    });
  }

  /**
   * @param rfqId
   * @returns Company
   * @throws ApiError
   */
  public static invitationsControllerFindBuyer(
    rfqId: number,
  ): CancelablePromise<Company> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/invitations/projects/{rfqId}/buyer-details',
      path: {
        rfqId: rfqId,
      },
    });
  }

  /**
   * @param rfqId
   * @param status
   * @returns ProjectInvitation
   * @throws ApiError
   */
  public static invitationsControllerUpdateStatus(
    rfqId: number,
    status: string,
  ): CancelablePromise<ProjectInvitation> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/invitations/projects/{rfqId}/status/{status}',
      path: {
        rfqId: rfqId,
        status: status,
      },
    });
  }

  /**
   * @returns ProjectInvitation
   * @throws ApiError
   */
  public static invitationsControllerFindAll(): CancelablePromise<
    Array<ProjectInvitation>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/invitations',
    });
  }

  /**
   * @param invitationId
   * @returns any
   * @throws ApiError
   */
  public static invitationsControllerWithdrawInvitation(
    invitationId: number,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/invitations/{invitationId}',
      path: {
        invitationId: invitationId,
      },
    });
  }

  /**
   * @param invitationId
   * @param status
   * @returns any
   * @throws ApiError
   */
  public static invitationsControllerUpdateNdaStatus(
    invitationId: number,
    status: any,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/invitations/{invitationId}/nda-status/{status}',
      path: {
        invitationId: invitationId,
        status: status,
      },
    });
  }

  /**
   * @param invitationId
   * @param requestBody
   * @returns ProjectInvitation
   * @throws ApiError
   */
  public static invitationsControllerRequestNdaAccess(
    invitationId: number,
    requestBody: RequestNdaDto,
  ): CancelablePromise<ProjectInvitation> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/invitations/{invitationId}/request-nda-access',
      path: {
        invitationId: invitationId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param projectId
   * @param isBookmarked
   * @returns ProjectInvitation
   * @throws ApiError
   */
  public static invitationsControllerToggleBookmark(
    projectId: number,
    isBookmarked: boolean,
  ): CancelablePromise<ProjectInvitation> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/invitations/projects/{projectId}/bookmark/{isBookmarked}',
      path: {
        projectId: projectId,
        isBookmarked: isBookmarked,
      },
    });
  }
}
