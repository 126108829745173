import { useEffect, useState } from 'react';

import { errorHandler } from '@/core/libs/error-handler';
import {
  InvitationsService,
  ProjectInvitation,
  ProjectsService,
} from '@/generated/api';
import { Project } from '@/generated/api/models/Project';

export interface UseRFQSummary {
  rfq?: Project;
  invitation?: ProjectInvitation;
  loading: boolean;
  refetch?: () => void;
}

export enum RFQNavitationType {
  TOKEN,
  LINK,
}

export interface UseRFQSummaryProps {
  idOrToken?: string;
  type: RFQNavitationType;
  isEdit?: boolean;
  refetch?: () => void;
}

export function useRFQSummary({
  idOrToken,
  type,
  isEdit = false,
}: UseRFQSummaryProps): UseRFQSummary {
  const [rfq, setRFQ] = useState<Project | undefined>();
  const [invitation, setInvitation] = useState<ProjectInvitation | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  const refetch = async () => {
    if (!idOrToken) return;
    setLoading(true);

    try {
      if (type === RFQNavitationType.LINK) {
        const project = await ProjectsService.projectsControllerFind(
          parseInt(idOrToken),
          isEdit ? isEdit : undefined,
        );
        setRFQ(project);

        InvitationsService.invitationsControllerFind(parseInt(idOrToken)).then(
          (invitationResponse) => setInvitation(invitationResponse),
        );
      }

      if (type === RFQNavitationType.TOKEN) {
        const invitationResponse =
          await InvitationsService.invitationsControllerFindFromToken(
            idOrToken,
          );
        const project = invitationResponse.project;
        setRFQ(project);
        setInvitation(invitationResponse);
      }
    } catch (error) {
      errorHandler.capture(error, { avoidFlashMessage: true });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idOrToken, type]);

  return {
    rfq,
    invitation,
    loading,
    refetch,
  };
}
